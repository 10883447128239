<template>
  <div class="about">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"
      :bgcolor="BannerKvData.bgcolor"/>
    <AboutIntroduction
      :titleEn="AboutIntroductionData.titleEn"
      :titleZh="AboutIntroductionData.titleZh"
      :intro="AboutIntroductionData.intro"
      :items="AboutIntroductionData.items"/>
    <AboutDoctorIntroduction
      :titleEn="AboutDoctorIntroductionData.titleEn"
      :titleZh="AboutDoctorIntroductionData.titleZh"
      :info="AboutDoctorIntroductionData.info"
      :link="AboutDoctorIntroductionData.link"
      :decoPic="AboutDoctorIntroductionData.decoPic"/>
    <AboutDoctors/>
    <AboutMore
      :items="AboutMoreData.items"/>
  </div>
</template>

<script>
import '@/assets/scss/about.scss';
import BannerKv from '@/components/BannerKv.vue';
import AboutIntroduction from '@/components/about/AboutIntroduction.vue';
import AboutDoctorIntroduction from '@/components/about/AboutDoctorIntroduction.vue';
import AboutDoctors from '@/components/about/AboutDoctors.vue';
import AboutMore from '@/components/about/AboutMore.vue';

import { getTraditionalArticles, getTcmTeamsContent } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

import bannerKvPic from '@/assets/img/index/about_fertility.jpg';
import more01 from '@/assets/img/about/more_1_new.jpg';
// import more02 from '@/assets/img/about/more_2.jpg';
import more03 from '@/assets/img/about/more_3_new.jpg';
import tcm from '@/assets/img/temp/tcm_update.jpg';

export default {
  name: 'aboutTcm',
  components: {
    BannerKv,
    AboutIntroduction,
    AboutDoctorIntroduction,
    AboutDoctors,
    AboutMore,
  },
  data() {
    return {
      BannerKvData: {
        titleEn: 'NUWA TCM CENTER',
        titleZh: this.$t('nuwaTcm'),
        backgroundPic: bannerKvPic,
        bgcolor: '#f8f8f8',
      },
      AboutIntroductionData: {
        titleEn: 'Title',
        titleZh: this.$t('introTcm'),
        intro: this.$t('tcmContent'),
        items: [],
      },
      AboutDoctorIntroductionData: {
        titleEn: 'DOCTOR<br>INTRODUCTION',
        titleZh: this.$t('tcmTeamsTitle'),
        info: this.$t('tcmTeamsIntro'),
        link: '/staff/traditional/t1',
        decoPic: tcm,
      },
      AboutMoreData: {
        items: [
          {
            link: '/about/ivf',
            pic: more01,
            titleEn: ' NUWA FERTILITY ',
            titleZh: this.$t('nuwaIvf'),
            info: this.$t('aboutIvfIntro'),
          },
          {
            link: '/about/lab',
            pic: more03,
            titleEn: 'NUWA LAB',
            titleZh: this.$t('nuwaLabs'),
            info: this.$t('aboutLabsIntro'),
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    getData() {
      this.AboutIntroductionData.items = [];
      getTraditionalArticles(
        this.getLangUUID,
        (response) => {
          response.data.data.forEach((elem) => {
            const temp = {
              pic: elem.icon,
              title: elem.title,
              brief_info: elem.brief_info,
              type: 'link',
              parameter: {
                class: 'tcm',
                id: elem.slug,
              },
            };
            this.AboutIntroductionData.items.push(temp);
          });
        },
      );
      getTcmTeamsContent(
        this.getLangUUID,
        (response) => {
          console.log(response);
          console.log(response.data.data);
          this.AboutDoctorIntroductionData = {
            titleEn: 'DOCTOR<br>INTRODUCTION',
            titleZh: response.data.data.title,
            info: response.data.data.content,
            link: '/staff/procreation/p1',
            decoPic: response.data.data.image,
          };
        },
      );
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        this.getData();
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.getData();
    }
  },
};
</script>
